import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GRAPH_CELL_SIZE } from 'constants';

import scss from './ActiveCell.scss';

function ActiveCell({ cy, focusPosition }) {
  const activeCellRef = useRef(null);

  const updateFocusStyles = useCallback(() => {
    if (!focusPosition || !activeCellRef.current) return;

    const zoom = cy.zoom();
    const pan = cy.pan();

    Object.assign(activeCellRef.current.style, {
      left: `${focusPosition.x * zoom + pan.x}px`,
      top: `${focusPosition.y * zoom + pan.y}px`,
      width: `${GRAPH_CELL_SIZE * zoom}px`,
      height: `${GRAPH_CELL_SIZE * zoom}px`,
    });
  }, [focusPosition, cy]);

  useEffect(() => {
    updateFocusStyles();
  }, [focusPosition, updateFocusStyles]);

  useEffect(() => {
    if (!cy) return;
    cy.on('zoom pan', updateFocusStyles);

    return () => {
      cy.off('zoom pan', updateFocusStyles);
    };
  }, [cy, updateFocusStyles]);

  return (
    <div
      className={scss.activeCell}
      ref={activeCellRef}
      style={{
        width: `${GRAPH_CELL_SIZE}px`,
        height: `${GRAPH_CELL_SIZE}px`,
      }}
    />
  );
}

ActiveCell.propTypes = {
  focusPosition: PropTypes.object,
  cy: PropTypes.object,
};

export default ActiveCell;
