import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ResizableBox } from 'react-resizable';

import { GRAPH_NODE_SIZE } from '@control-front-end/common/constants/graphActors';
import '@control-front-end/common/components/ResizableWrap/ResizableWrap.scss';

import useCellsResize, { POSITIONING } from './useCellsResize';
import scss from './ResizableCells.scss';

const ResizeHandle = React.forwardRef((props, ref) => {
  const { handleAxis, showHandles, transform, ...restProps } = props;
  return (
    <div
      ref={ref}
      styleName={cn(`resizable-handle ${handleAxis}`, { showHandles })}
      style={{ pointerEvents: 'all' }}
      {...restProps}
    />
  );
});

function ResizableCells({
  showHandles,
  position,
  onResizeStart: onResizeStartProp,
  onResizeStop: onResizeStopProp,
  minConstraints: minConstraintsProp,
  size: sizeProp,
  initialSize: initialSizeProp,
  offset: offsetProp,
  children,
  ...props
}) {
  const {
    size,
    positioning,
    offset,
    onResizeStart,
    onResizeStop,
    setNodeSize,
    ...rest
  } = useCellsResize({
    minConstraints: minConstraintsProp,
    size: initialSizeProp,
    offset: offsetProp,
  });

  return (
    <ResizableBox
      width={sizeProp ? sizeProp.width : size.width}
      height={sizeProp ? sizeProp.height : size.height}
      onResizeStop={(e, data) => {
        onResizeStopProp?.(e, { ...data, offset });
        onResizeStop?.(e, data);
      }}
      onResizeStart={(...args) => {
        onResizeStartProp?.(...args);
        onResizeStart?.(...args);
      }}
      handle={<ResizeHandle showHandles={showHandles} />}
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        transform:
          positioning === POSITIONING.leftTop
            ? `translate(${position.x - offset.left - GRAPH_NODE_SIZE / 2}px, ${
                position.y - offset.top - GRAPH_NODE_SIZE / 2
              }px)`
            : '',
        /**
         * Bind ResizableBox position to the right-bottom corner - to avoid
         * it's jumping when resizing by the left or top handles
         * @see https://github.com/react-grid-layout/react-resizable/pull/136
         */
        right:
          positioning === POSITIONING.rightBottom
            ? `calc(100% - ${
                position.x + offset.right + GRAPH_NODE_SIZE / 2
              }px)`
            : '',
        bottom:
          positioning === 'rightBottom'
            ? `calc(100% - ${
                position.y + offset.bottom + GRAPH_NODE_SIZE / 2
              }px)`
            : '',
      }}
      {...props}
      {...rest}
      draggableOpts={{
        grid: [
          GRAPH_NODE_SIZE * document.cy.zoom(),
          GRAPH_NODE_SIZE * document.cy.zoom(),
        ],
      }}
      transformScale={document.cy.zoom()}
    >
      <div className={scss.wrap}>{children}</div>
    </ResizableBox>
  );
}

ResizableCells.defaultProps = {
  axis: 'both',
  resizeHandles: ['s', 'w', 'e', 'n'],
  showHandles: false,
  lockAspectRatio: false,
};

ResizableCells.propTypes = {
  children: PropTypes.element.isRequired,
  initialSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  offset: PropTypes.object,
  axis: PropTypes.oneOf(['both', 'x', 'y']),
  resizeHandles: PropTypes.array,
  showHandles: PropTypes.bool,
  lockAspectRatio: PropTypes.bool,
  minConstraints: PropTypes.array,
  onResizeStart: PropTypes.func,
  onResizeStop: PropTypes.func,
};

export default ResizableCells;
